var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("a-spin", {
    attrs: {
      spinning: _vm.spinning
    }
  }, [_c("div", {
    staticClass: "constainer-header"
  }, [_c("a-form", {
    attrs: {
      labelCol: {
        style: "width: 85px"
      },
      form: _vm.form,
      layout: "vertical"
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "内容ID:"
    }
  }, [_vm.editId ? [_c("span", [_vm._v(_vm._s(_vm.source.contentid))])] : _c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["contentids", {
        rules: [{
          required: true,
          message: "请输入内容ID",
          whitespace: true
        }]
      }],
      expression: "[\n          'contentids',\n          {\n            rules: [{\n              required: true,\n              message: '请输入内容ID',\n              whitespace: true,\n            }],\n          },\n        ]"
    }],
    attrs: {
      autoComplete: "off",
      size: "large",
      placeholder: "请输入，多个一行一个",
      type: "textarea",
      rows: 5
    }
  })], 2), _c("a-form-item", {
    attrs: {
      label: "描述:"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["desc", {
        rules: [{
          max: 32,
          message: "最多32个字符"
        }],
        initialValue: _vm.item.desc
      }],
      expression: "[\n          'desc',\n          {\n            rules: [{\n              max: 32,\n              message: '最多32个字符'\n            }],\n            initialValue: item.desc,\n          },\n        ]"
    }],
    attrs: {
      autoComplete: "off",
      size: "large",
      placeholder: "请输入描述",
      "max-length": 32
    }
  })], 1), _c("a-form-item", {
    staticStyle: {
      display: "flex",
      "justify-content": "space-between"
    }
  }, [_c("a-button", {
    staticClass: "btn btn_l",
    attrs: {
      size: "large"
    },
    on: {
      click: _vm.cancel
    }
  }, [_vm._v(_vm._s("取消"))]), _c("a-button", {
    staticClass: "btn btn_r",
    staticStyle: {
      "margin-left": "10px"
    },
    attrs: {
      size: "large",
      type: "primary",
      loading: _vm.loading
    },
    on: {
      click: _vm.submit
    }
  }, [_vm._v("确定")])], 1)], 1)], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };
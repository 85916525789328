import _toConsumableArray from "D:/projects/operation-management-background/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import _objectSpread from "D:/projects/operation-management-background/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.string.trim.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.set.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import { DcFeaturesApi } from "@/api";
export default {
  name: "dcFeaturesSubForm",
  components: {},
  props: {
    editId: [String, Number],
    featureid: [String, Number],
    action: {
      type: String,
      default: function _default() {
        return "add";
      }
    },
    source: {
      type: Object,
      default: function _default() {}
    }
  },
  data: function data() {
    return {
      loaded: false,
      // 仅针对edit类型使用

      spinning: false,
      loading: false,
      item: _objectSpread({}, this.source),
      form: this.$form.createForm(this)
    };
  },
  methods: {
    cancel: function cancel() {
      this.$emit("cancel");
    },
    submit: function submit() {
      var _this = this;
      if (this.loading) return;
      this.form.validateFields(function (err, values) {
        if (!err) {
          _this.loading = true;
          var params = _objectSpread({}, values);
          var method = "subAdd";
          if (_this.editId) {
            method = "subUpdate";
            params.featureid = _this.featureid;
            params.contentid = _this.source.contentid;
          } else {
            var contentids = (params.contentids || '').trim();
            var lines = contentids.split(/[(\r\n)\r\n]+/);
            lines = lines.map(function (item) {
              return item.trim();
            });
            lines = lines.filter(function (item) {
              return item.trim() ? true : false;
            });
            if (lines.length == 0) {
              _this.toast('请正确输入内容ID', 'error');
              return false;
            }
            params.contentids = _toConsumableArray(new Set(lines));
            params.featureid = _this.featureid;
          }
          DcFeaturesApi[method](_objectSpread({}, params)).then(function () {
            _this.toast("操作成功", "success");
            _this.$emit("submit");
          }).catch(function (err) {
            _this.loading = false;
            // this.toast("操作失败," + err, "error");
          });
        }
      });
    }
  }
};
import "core-js/modules/es.number.constructor.js";
import { DcFeaturesApi } from "@/api";
export default {
  name: "DcSubFeaturesLog",
  components: {},
  props: {
    editId: [String, Number],
    featureid: [String, Number],
    action: {
      type: String,
      default: function _default() {
        return "pass";
      }
    }
  },
  data: function data() {
    return {
      spinning: false,
      logs: [] // 日志列表
    };
  },

  computed: {},
  created: function created() {
    this.loadLogs();
  },
  methods: {
    loadLogs: function loadLogs() {
      var _this = this;
      this.spinning = true;
      DcFeaturesApi.subLogs({
        featureid: this.featureid,
        contentid: this.editId
      }).then(function (res) {
        _this.spinning = false;
        _this.logs = res;
      }).catch(function () {});
    },
    cancel: function cancel() {
      this.$emit("cancel");
    }
  }
};
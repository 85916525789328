import _objectSpread from "D:/projects/operation-management-background/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.string.trim.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import { mapActions, mapGetters } from "vuex";
import Mixin from "@/utils/mixin";
import TableMixin from "@/utils/tableMixin";
import { searchKeys2 as searchKeys, columns2 as columns } from "./config/index";
import { DcFeaturesApi } from "@/api";
import SubForm from "./components/subForm.vue";
import SubLog from "./components/subLog.vue";
import cloneDeep from "lodash/cloneDeep";
import { BATCH_PASS, BATCH_UNPASS, ADD } from "@/utils/buttons";
export default {
  name: "dcSubFeatures",
  components: {
    SubForm: SubForm,
    SubLog: SubLog
  },
  data: function data() {
    return {
      featureid: undefined,
      createForm: this.$form.createForm(this, {
        name: "dcSubFeatures"
      }),
      loading: false,
      columns: columns,
      searchKeys: searchKeys,
      tipsDesc: "",
      isConfirmVisible: false,
      subLoading: false,
      action: "add",
      // 操作:add,edit,delete
      editId: undefined,
      modalVisible: false,
      defaultIndex: 0,
      types: [{
        id: 1,
        name: "生效"
      }, {
        id: 2,
        name: "失效"
      }],
      source: {} // 编辑需要使用
    };
  },

  mixins: [Mixin, TableMixin],
  computed: _objectSpread(_objectSpread({}, mapGetters("dcFeatures", ["contentidtypeListObj"])), {}, {
    getDataWithKey: function getDataWithKey() {
      return function (key) {};
    },
    modalTitle: function modalTitle() {
      return this.action == "pass" ? "批量通过" : this.action == "unpass" ? "批量驳回" : this.action == "add" ? "添加ID" : this.action == "edit" ? "编辑ID" : this.action == "log" ? "日志" : "";
    },
    status: function status() {
      return this.types[this.defaultIndex].id;
    },
    statusText: function statusText() {
      return this.types[this.defaultIndex].name;
    },
    module: function module() {
      return 'dcSubFeatures';
    }
  }),
  created: function created() {
    var _this$$route$query;
    this.featureid = (_this$$route$query = this.$route.query) === null || _this$$route$query === void 0 ? void 0 : _this$$route$query.id;
    if (!this.featureid) {
      this.toast('id不能为空', 'error');
      this.$router.push('/dcFeatures/index');
      return false;
    }
    window.GLOBAL.vbus.$emit("pageBtnDisabled", [BATCH_PASS]);
    this.getList();
    this.getContentidtypeList();
  },
  methods: _objectSpread(_objectSpread({}, mapActions('dcFeatures', ['getContentidtypeList'])), {}, {
    onPageSizeChange: function onPageSizeChange(page, size) {
      this.page = page;
      this.size = size;
      this.getList();
    },
    onShowSizeChange: function onShowSizeChange(page, size) {
      this.page = 1;
      this.size = size;
      this.getList();
    },
    // 取消启用/禁用
    handleDelCancle: function handleDelCancle() {
      this.isConfirmVisible = false;
      this.subLoading = false;
    },
    // 确定启用/禁用
    handleDelSubmit: function handleDelSubmit() {
      var _this = this;
      this.subLoading = true;
      DcFeaturesApi.subUpdateStatus({
        featureid: this.featureid,
        contentids: this.editId,
        status: this.status == 1 ? 2 : 1
      }).then(function () {
        _this.toast("操作成功", "success");
        _this.isConfirmVisible = false;
        _this.subLoading = false;
        _this.getList();
      }).catch(function () {
        _this.isConfirmVisible = false;
        _this.subLoading = false;
      });
    },
    getList: function getList() {
      var _this2 = this;
      this.createForm.validateFields(function (err, values) {
        if (!err) {
          _this2.selectedRowKeys = [];
          var formValues = cloneDeep(values);
          if (formValues.date && formValues.date.length > 0) {
            formValues.starttime = formValues.date[0].valueOf();
            formValues.endtime = formValues.date[1].valueOf();
          }
          delete formValues.date;

          // 多行
          var contentids = (formValues.contentids || '').trim();
          if (contentids) {
            var lines = contentids.split(/[(\r\n)\r\n]+/);
            lines = lines.map(function (item) {
              return item.trim();
            });
            lines = lines.filter(function (item) {
              return item.trim() ? true : false;
            });
            if (lines.length > 0) formValues.contentids = lines;else delete formValues.contentids;
          } else {
            delete formValues.contentids;
          }

          // 排序
          if (_this2.sortOrder) {
            formValues.orderfield = _this2.sortField;
            formValues.ordertype = _this2.sortOrder == 'ascend' ? 1 : 2;
          }
          _this2.loading = true;
          var params = _objectSpread({
            page: _this2.page,
            size: _this2.size,
            status: _this2.status,
            featureid: _this2.featureid
          }, formValues);
          DcFeaturesApi.sublist(_objectSpread({}, params)).then(function (res) {
            _this2.loading = false;
            _this2.dataSource = (res === null || res === void 0 ? void 0 : res.list) || [];
            _this2.total = (res === null || res === void 0 ? void 0 : res.total) || _this2.total;
          }).catch(function () {
            _this2.dataSource = [];
          }).finally(function () {
            setTimeout(function () {
              _this2.loading = false;
            }, 3000);
          });
        } else {
          console.log(err);
        }
      });
    },
    handleSearch: function handleSearch() {
      this.page = 1;
      this.getList();
    },
    handleReset: function handleReset() {
      this.page = 1;
      this.size = 20;
      this.createForm.resetFields();
      this.handleSearch();
    },
    defineTipsDesc: function defineTipsDesc(action) {
      if (action == BATCH_PASS) {
        return '是否<span class="color-red">确认恢复</span>所选内容ID，点击确认后将执行变更其状态为“生效”';
      } else if (action == BATCH_UNPASS) {
        return '是否<span class="color-red">确认删除</span>所选内容ID，点击确认后将执行变更其状态为“失效”';
      }
      return "";
    },
    handleAction: function handleAction(action, record) {
      this.action = action;
      this.editId = (record === null || record === void 0 ? void 0 : record.id) || "";
      if (action == "delete") {
        this.tipsDesc = this.defineTipsDesc(BATCH_UNPASS);
        this.editId = [record === null || record === void 0 ? void 0 : record.contentid];
        this.isConfirmVisible = true;
      } else if (action == "restore") {
        this.tipsDesc = this.defineTipsDesc(BATCH_PASS);
        this.editId = [record === null || record === void 0 ? void 0 : record.contentid];
        this.isConfirmVisible = true;
      } else if (action == "edit") {
        this.modalVisible = true;
        this.source = record;
      } else if (action == ADD) {
        this.modalVisible = true;
        this.source = {};
      } else if (action == "log") {
        this.modalVisible = true;
        this.editId = (record === null || record === void 0 ? void 0 : record.contentid) || '';
        return;
      }
    },
    tabClick: function tabClick(e, index) {
      // this.createForm.resetFields();
      this.defaultIndex = index;
      window.GLOBAL.vbus.$emit("pageBtnDisabled", [this.status == 1 ? BATCH_PASS : BATCH_UNPASS]);
      this.page = 1;
      this.size = 20;
      this.handleSearch();
    },
    /*******表单相关start********/handleFormCancel: function handleFormCancel() {
      // console.log("取消");
      this.modalVisible = false;
    },
    handleFormSubmit: function handleFormSubmit() {
      this.modalVisible = false;
      this.getList();
    },
    /*******表单相关end********/
    /*******表格复选框start********/
    pageBtnClicked: function pageBtnClicked(module, action) {
      // console.log(action, ADD);
      if (action == BATCH_PASS) {
        this.tipsDesc = this.defineTipsDesc(BATCH_PASS);
      } else if (action == BATCH_UNPASS) {
        this.tipsDesc = this.defineTipsDesc(BATCH_UNPASS);
      } else if (action == ADD) {
        this.handleAction(action, {});
        return;
      }
      var ids = this.getAllCheckedKey("contentid");
      if (ids.length == 0) {
        this.toast("请先选择明细记录", "error");
        return;
      }
      this.editId = ids;
      this.isConfirmVisible = true;
    } /*******表格复选框end********/
  })
};
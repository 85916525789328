var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("a-spin", {
    attrs: {
      spinning: _vm.spinning
    }
  }, [_c("div", {
    staticClass: "constainer-header"
  }, _vm._l(_vm.logs, function (item) {
    return _c("div", {
      key: item.id,
      staticClass: "row"
    }, [_c("div", {
      staticClass: "time"
    }, [_vm._v(_vm._s(item.createdtime))]), _c("div", {
      staticClass: "record"
    }, [_c("span", {
      staticClass: "nickname"
    }, [_vm._v(_vm._s(item.createdby))]), _c("span", {
      staticClass: "status"
    }, [_vm._v("[行为]："), _c("span", [_vm._v(_vm._s(item.operation == 1 ? "新建" : item.operation == 2 ? "编辑" : item.operation == 3 ? "恢复" : item.operation == 4 ? "删除" : "---"))])])])]);
  }), 0)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };